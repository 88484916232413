export default [
  {
    path: '/games/game-list',
    name: 'game-list',
    component: () => import('@/views/games/game-list/GameList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Game List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/game-configuration',
    name: 'game-configuration',
    component: () => import('@/views/games/game-configuration/GameConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Game Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/game-configuration/:id',
    name: 'game-configuration-id',
    component: () => import('@/views/games/game-configuration/GameConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Game Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/game-edit/:id',
    name: 'game-edit-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/games/game-configuration/GameEdit.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Game Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/game-view/:id',
    name: 'game-view',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/games/game-view/GameView.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Game View',
          active: true,
        },
      ],
    },
  },
]
